import {
  Card,
  Image,
  Text,
  Table,
  Button,
  Group,
  Modal,
  rem,
  AspectRatio,
  Tooltip,
  ActionIcon,
  createStyles,
} from "@mantine/core";
import { useDisclosure, useHover } from "@mantine/hooks";
import { Carousel, useAnimationOffsetEffect } from "@mantine/carousel";
import { useEffect, useRef, useState } from "react";
import { Embla } from "@mantine/carousel";
import { IconBrandAmazon, IconBrandInstagram } from "@tabler/icons-react";
import ImageMarker, { MarkerComponentProps } from "react-image-marker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const useStyles = createStyles((theme) => ({
  setupCard: {
    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
    transition: "box-shadow 150ms ease, transform 100ms ease",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },
}));

interface SetupCardProps {
  data: {
    link: string;
    name: string;
    instagramLink: string;
    images: {
      src: string;
      markers: { top: number; left: number; tableRowId: string }[];
    }[];
    products: { name: string; price: string; link: string }[];
  };
}

export default function SetupCard({ data }: SetupCardProps) {
  const modalRef = useRef();

  const location = useLocation();
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [opened, { close }] = useDisclosure(false);
  const [embla, setEmbla] = useState<Embla | null>(null);
  const TRANSITION_DURATION = 200;
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);
  const navigate = useNavigate();

  useEffect(() => {
    const observerRefValue = modalRef.current;
    if (observerRefValue) disableBodyScroll(observerRefValue);
    return () => {
      if (observerRefValue) {
        enableBodyScroll(observerRefValue);
      }
    };
  }, []);

  useEffect(() => {
    if (highlightedIndex) {
      const timeoutId = setTimeout(() => {
        setHighlightedIndex(null);
      }, 1500);

      return () => clearTimeout(timeoutId);
    }
  }, [highlightedIndex]);

  const targetRefs = useRef<(HTMLTableRowElement | null)[]>([]);

  const handleClick = (index: number) => {
    if (targetRefs.current[index]) {
      targetRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
      setHighlightedIndex(index);
    }
  };

  //const [toolTipsOpened, setTipsOpened] = useState(false);
  const CustomMarker = (props: MarkerComponentProps) => {
    const { hovered, ref } = useHover();
    let itemNumber = String(props.itemNumber);
    let num = +itemNumber;
    if (num >= data.products.length) num -= 1;
    console.log("props: ", props);
    //@ts-ignore
    let tableRowIdString = String(props.tableRowId);
    let targetNum = +tableRowIdString;
    console.log("tableRowIdString: ", targetNum);

    return (
      <Tooltip
        position="bottom"
        label={data.products[targetNum].name}
        style={{ border: "2px solid white" }}
        color="black"
        withArrow={false}
      >
        <div
          ref={ref}
          onClick={() => handleClick(targetNum)}
          style={{
            backgroundColor: hovered ? "#34a4eb" : "white",
            border: "1px solid black",
            width: "1em",
            height: "1em",
            borderRadius: 50,
            color: "black",
          }}
        ></div>
      </Tooltip>
    );
  };

  // const openAllTooltips = () => {
  //   setTipsOpened(!toolTipsOpened);
  // };

  const closeModal = () => {
    close();
    navigate("/");
  };

  // const openModal = (link: string) => {
  //   //open();
  //   //navigate(link);
  // };

  //   onAddMarker={(marker: Marker) =>
  //                           setMarkers([...markers, marker])
  //                         }

  const { classes, theme } = useStyles();

  return (
    <Card
      shadow="sm"
      padding="lg"
      className={classes.setupCard}
      radius="md"
      withBorder
      style={{ width: 300 }}
    >
      <Card.Section>
        <AspectRatio ratio={1.5}>
          {/* @ts-ignore */}
          <img src={data.cardImage} alt={data.name} />
        </AspectRatio>
      </Card.Section>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={500}>{data.name}</Text>
        {/* <Badge color="green" variant="light">
          Minimalist
        </Badge> */}
      </Group>

      {/* <Text size="sm" color="dimmed">
        With Fjord Tours you can explore more of the magical fjord landscapes
        with tours and activities on and around the fjords of Norway
      </Text> */}
      <Modal
        transitionProps={{ duration: TRANSITION_DURATION }}
        opened={opened}
        onClose={closeModal}
        title={
          <div
            style={{
              display: "flex",
              alignContent: "center",
            }}
          >
            <Text>{data.name}</Text>
            <ActionIcon
              onClick={() => window.open(data.instagramLink, "_blank")}
              size="md"
            >
              <IconBrandInstagram size="1.5em" stroke={1.5} />
            </ActionIcon>
          </div>
        }
        centered
      >
        <Carousel
          getEmblaApi={setEmbla}
          withIndicators
          mx="auto"
          align={"center"}
          slideSize={"100%"}
          slideGap={15}
          sx={(theme) => ({
            width: "100%",
            border: "0px solid red",
            backgroundImage: theme.fn.gradient({
              from: "blue",
              to: "#57d8ff",
              deg: 0,
            }),
          })}
          styles={{
            control: {
              "&[data-inactive]": {
                opacity: 0,
                cursor: "default",
              },
            },
            indicator: {
              width: rem(12),
              height: rem(4),
              transition: "width 250ms ease",

              "&[data-active]": {
                width: rem(40),
              },
            },
          }}
        >
          {data.images.map((image, index) => {
            return (
              <Carousel.Slide key={index} style={{}}>
                <AspectRatio ratio={4 / 5} maw={700} mx="auto">
                  {/* <Image
                    withPlaceholder
                    src={image}
                    height={"100%"}
                    width={"100%"}
                    alt="Norway"
                  /> */}
                  <div style={{ border: "0px solid red" }}>
                    <div style={{ border: "0px solid green" }}>
                      <ImageMarker
                        markerComponent={CustomMarker}
                        src={image.src}
                        markers={image.markers}
                      />
                    </div>
                  </div>
                </AspectRatio>
              </Carousel.Slide>
            );
          })}
        </Carousel>
        <div
          style={{
            overflow: "auto",
            height: "30vh",
            borderBottom: "1px solid lightgray",
          }}
        >
          <Table
            sx={{
              tableLayout: "fixed",
            }}
            withBorder
          >
            <thead style={{}}>
              <tr>
                <th style={{ width: "50%" }}>Product</th>
                <th style={{ width: "50%" }}>Price</th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: 100, overflow: "scroll" }}>
              {data.products.map((element, index) => (
                <tr
                  ref={(ref) => (targetRefs.current[index] = ref)}
                  key={element.name}
                  style={{
                    transition: "background-color 0.5s",
                    backgroundColor:
                      highlightedIndex === index ? "lightblue" : undefined,
                  }}
                >
                  <td style={{ wordBreak: "break-word" }}>{element.name}</td>
                  <td style={{ maxWidth: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "right",
                        justifyContent: "flex-end",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Text>${element.price}</Text> */}
                      <Button
                        sx={{
                          fontSize: 12,
                          padding: 5,
                          paddingTop: 0,
                          paddingBottom: 3,
                          marginLeft: 10,
                        }}
                        onClick={() => window.open(element.link, "_blank")}
                        leftIcon={<IconBrandAmazon />}
                        variant="gradient"
                        gradient={{ from: "teal", to: "lime", deg: 105 }}
                      >
                        Buy on Amazon
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Text
          c="dimmed"
          sx={{ textAlign: "center", fontSize: 14, marginTop: 20 }}
        >
          Affiliate Disclosure: This list may contain affiliate links so we may
          receive a commission if you purchase products linked above
        </Text>
        {/* <List
          style={{
            border: "1px solid red",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            Clone or download repository from GitHub
          </List.Item>
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            Install dependencies with yarn
          </List.Item>
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            To start development server run npm start command
          </List.Item>
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            Run tests to make sure your changes do not break the build
          </List.Item>
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            Submit a pull request once you are done
          </List.Item>
        </List> */}
      </Modal>
      <Button
        component={Link}
        state={{ background: location }}
        variant="gradient"
        gradient={{ from: "teal", to: "lime", deg: 105 }}
        to={data.link}
        color="blue"
        fullWidth
        mt="md"
        radius="md"
      >
        See Product List
      </Button>
    </Card>
  );
}
