import { memo, useCallback, useEffect, useRef, useState } from "react";
import React, { Component, type ReactNode } from "react";
import {
  Text,
  Group,
  Button,
  createStyles,
  rem,
  AspectRatio,
  Portal,
  Image,
  Modal,
  Affix,
  Center,
  Space,
  useMantineTheme,
  ActionIcon,
  Tooltip,
  Select,
  Slider,
} from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import {
  IconCloudUpload,
  IconX,
  IconDownload,
  IconGripVertical,
  IconCross,
  IconZoomIn,
  IconPlus,
} from "@tabler/icons-react";
import { Carousel } from "@mantine/carousel";
import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";
import { Embla } from "@mantine/carousel";
import { useDisclosure, useHover, useListState } from "@mantine/hooks";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  useKeyboardSensor,
  useMouseSensor,
} from "react-beautiful-dnd";
import useTouchSensor from "./use-touch-sensor";
import "./styles.css";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropimage";

import ReactDOM from "react-dom";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const useStyles = createStyles((theme) => ({
  item: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.sm,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    width: rem(70),
    height: "fit-content",
    border: "1px solid lightgray",
  },

  wrapper: {
    position: "relative",
    marginBottom: rem(30),
  },

  dropzone: {
    borderWidth: rem(1),
    paddingBottom: rem(50),
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: "absolute",
    width: rem(250),
    left: `calc(50% - ${rem(125)})`,
    bottom: rem(-20),
  },

  modal: {
    backgroundColor: "green",
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    display: "flex",
    padding: 15,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
  },

  addTagsModal: {
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.md,
  },
}));

type ItemProps = {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  image: ImageProps;
  products: [];
};

type ImageProps = {
  position: number;
  id: string;
  mass: number;
  symbol: string;
  name: string;
  fileName: string;
  src: string;
  markers: [];
};

type ImagesProps = {
  products: [];
};

const portal: HTMLElement = document.createElement("div");
portal.classList.add("my-super-cool-portal");

if (!document.body) {
  throw new Error("body not ready for portal creation!");
}

document.body.appendChild(portal);

export function DropzoneButton(props: ImagesProps) {
  const { classes, theme, cx } = useStyles();
  const openRef = useRef<() => void>(null);
  const [files, setFiles] = useState<{}[]>([]);
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [state, handlers] = useListState<ImageProps>([]);
  const products = props.products;
  const [images, setImages] = useState([]);

  const CustomMarker = (props: MarkerComponentProps) => {
    //@ts-ignore
    let tableRowId = props.markerData.hasOwnProperty("tableRowId")
      ? //@ts-ignore
        props.markerData["tableRowId"]
      : null;
    console.log("this is the table row:", tableRowId);
    let matchingProduct = tableRowId ? products[tableRowId] : null;
    console.log("this is the matching :) :", matchingProduct);
    const { hovered, ref } = useHover();
    const [targetRow, setTargetRow] = useState(tableRowId ? tableRowId : "");
    const [productName, setProductName] = useState(
      matchingProduct ? matchingProduct["name"] : ""
    );
    const [value, setValue] = useState(
      matchingProduct ? matchingProduct["name"] : ""
    );
    let itemNumber = props.itemNumber;
    //@ts-ignore
    let fileName = props.fileName;
    const selectRef = useRef<HTMLInputElement>(null);

    console.log("props data: ", props);

    const onProductChange = (
      e: string,
      copyProducts: [],
      itemNumber: number
    ) => {
      console.log("events: ", e);
      console.log("here is the state in files: ", files);
      const index = copyProducts.findIndex(function (product) {
        //@ts-ignore
        return product.value == e;
      });
      //@ts-ignore
      console.log("this is the marker: ", props.markerData.itemNumber);
      //@ts-ignore
      const copyMarkers = JSON.parse(JSON.stringify(props.markers));
      //@ts-ignore
      copyMarkers[props.markerData.itemNumber]["tableRowId"] = String(index);
      //@ts-ignore
      props.setMarkers(copyMarkers);
      // const currentFileIndex = files.findIndex(
      //   //@ts-ignore
      //   (file) => file.fileName === fileName
      // );
      // const updatedFile = Object.assign({}, files[currentFileIndex]);
      // //@ts-ignore
      // updatedFile["markers"][itemNumber]["tableRowId"] = String(index);
      // const newFiles = files.slice();
      // newFiles[currentFileIndex] = updatedFile;
      // //setFiles(newFiles);
      // console.log("found index: ", index);
      setTargetRow(String(index));
      setProductName(e);

      setValue(e);
    };

    //@ts-ignore
    const copyProducts = [];
    products.map((element: { name: string; value: string }, index) => {
      let selectObject = {
        index: index,
        label: element.name,
        value: element.name,
      };

      copyProducts.push(selectObject);
    });
    //@ts-ignore
    const [openedProducts, handlers] = useDisclosure(false);

    const closeProductModal = () => {
      console.log("files in product: ", files);
      handlers.toggle();
    };
    return (
      <>
        {" "}
        <Tooltip
          label={
            targetRow === ""
              ? "Click to link tag to a product"
              : //@ts-ignore
                productName
          }
          withArrow
          width={190}
        >
          <div
            onClick={handlers.open}
            ref={ref}
            style={{
              backgroundColor: hovered ? "#34a4eb" : "white",
              border: "1px solid black",
              width: "1em",
              height: "1em",
              borderRadius: 50,
              color: "black",
            }}
          ></div>
        </Tooltip>
        <Modal
          padding={"xl"}
          centered
          closeOnClickOutside
          withOverlay
          opened={openedProducts}
          onClose={closeProductModal}
          title="Link to a product"
        >
          <Select
            //@ts-ignore
            value={value}
            //@ts-ignore
            onChange={(event) => onProductChange(event, copyProducts)}
            ref={selectRef}
            searchable
            nothingFound={"No products found."}
            dropdownPosition="bottom"
            style={{}}
            label="Product list"
            placeholder="Pick one"
            //@ts-ignore
            data={copyProducts}
            transitionProps={{
              transition: "pop-top-left",
              duration: 80,
              timingFunction: "ease",
            }}
            withinPortal
          />
        </Modal>
      </>
    );
  };

  useEffect(() => {
    console.log("updated state: ", files);
    //setFiles([]);
  }, [files]);

  const [globalAspect, setGlobalAspect] = useState<number>(4 / 5);
  const [imageEditorSettings, setImageEditorSettings] = useState({});

  const PortalAwareItem = (props: ItemProps) => {
    const provided: DraggableProvided = props.provided;
    const snapshot: DraggableStateSnapshot = props.snapshot;
    const usePortal: boolean = snapshot.isDragging;
    const products: [] = props.products;
    const item: ImageProps = props.image;
    //const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [aspect, setAspect] = useState<number>(globalAspect);
    const imgRef = useRef<HTMLImageElement>(null);
    const id = item.id;
    const [initialCroppedArea, setInitialCroppedArea] = useState(
      //@ts-ignore
      imageEditorSettings.hasOwnProperty(id)
        ? //@ts-ignore
          imageEditorSettings[id]["initialCroppedArea"]
        : undefined
    );

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(undefined);
    console.log("initialCroppedArea: ", initialCroppedArea);
    console.log("imageEditorSettings: ", imageEditorSettings);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedImage, setCroppedImage] = useState(null);

    //@ts-ignore
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setInitialCroppedArea(croppedArea);
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
      if (aspect) {
        console.log("aspect");
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, aspect));
      }
    }

    //let itemNumber = props.itemNumber;
    //@ts-ignore
    let fileName = item.fileName;

    //@ts-ignore
    const copyProducts = [];
    products.map((element: { name: string; value: string }, index) => {
      let selectObject = {
        index: index,
        label: element.name,
        value: element.name,
      };

      copyProducts.push(selectObject);
    });
    //@ts-ignore
    const [openedProducts, handlers] = useDisclosure(false);

    const [opened, { open, close }] = useDisclosure(false);
    const [imageResizeOpened, imageResizeHandlers] = useDisclosure(false);
    //const [newFiles, setNewFiles] = useState([]);
    //let fileName = item.fileName;

    const currentFileIndex = files.findIndex(
      //@ts-ignore
      (file) => file.fileName === fileName
    );
    const updatedFile = Object.assign({}, files[currentFileIndex]);
    //@ts-ignore
    const [markers, setMarkers] = useState(
      //@ts-ignore
      updatedFile.markers && updatedFile.markers.length > 0
        ? //@ts-ignore
          updatedFile.markers
        : []
    );
    //@ts-ignore
    console.log("existing markers: ", updatedFile.markers);
    //@ts-ignore
    console.log("current markers: ", markers);

    const closeTagsModal = () => {
      console.log("here are the updated markers: ", markers);
      console.log("here are the updated files: ", files);
      console.log("here is the filename: ", item.fileName);
      let fileName = item.fileName;

      const currentFileIndex = files.findIndex(
        //@ts-ignore
        (file) => file.fileName === fileName
      );
      const updatedFile = Object.assign({}, files[currentFileIndex]);
      //@ts-ignore
      updatedFile["markers"] = markers;
      const newFiles = files.slice();
      newFiles[currentFileIndex] = updatedFile;
      setFiles(newFiles);
      //@ts-ignore
      props.updateCurrentSetupFiles(newFiles);
      close();
      //setFiles(newFiles);
    };

    const closeImageResizerModal = () => {
      console.log("closing");
      showCroppedImage();
      setGlobalAspect(aspect);
      imageResizeHandlers.close();
      console.log("files: ", files);
      let imageEditorSettingsCopy = JSON.parse(
        JSON.stringify(imageEditorSettings)
      );
      imageEditorSettingsCopy[id] = {
        croppedAreaPixels: croppedAreaPixels,
        initialCroppedArea: initialCroppedArea,
      };
      setImageEditorSettings(imageEditorSettingsCopy);
    };
    const [output, setOutput] = useState(undefined);
    const showCroppedImage = useCallback(async () => {
      try {
        let fileName = item.fileName;
        const croppedImage = await getCroppedImg(
          item.src,
          croppedAreaPixels,
          rotation
        );
        console.log("donee", { croppedImage });
        //@ts-ignore
        let updatedImage = new File([croppedImage], fileName, {
          type: "image/jpeg",
        });
        console.log("updatedImage: ", updatedImage);
        //@ts-ignore

        const currentFileIndex = files.findIndex(
          //@ts-ignore
          (file) => file.fileName === fileName
        );
        const updatedFile = Object.assign({}, files[currentFileIndex]);
        //@ts-ignore
        updatedFile["rawFile"] = updatedImage;
        //@ts-ignore
        updatedFile["blobUrl"] = croppedImage;
        const newFiles = files.slice();
        newFiles[currentFileIndex] = updatedFile;
        setFiles(newFiles);
        //@ts-ignore
        props.updateCurrentSetupFiles(newFiles);
        //@ts-ignore
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    }, [croppedAreaPixels, rotation]);

    const onAddMarker = (marker: Marker, products: [], fileName: string) => {
      //@ts-ignore
      //marker["products"] = products;
      //@ts-ignore
      //marker["fileName"] = fileName;
      console.log("adding marker: ", marker);

      // 1. Find the todo with the provided id
      const currentFileIndex = files.findIndex(
        //@ts-ignore
        (file) => file.fileName === fileName
      );
      // 2. Mark the todo as complete
      const updatedFile = Object.assign({}, files[currentFileIndex]);
      let existingMarkers = JSON.parse(
        //@ts-ignore
        JSON.stringify(updatedFile["markers"])
      );
      existingMarkers.push(marker);
      //@ts-ignore
      updatedFile["markers"] = existingMarkers;
      // 3. Update the todo list with the updated todo
      const newFiles = files.slice();
      newFiles[currentFileIndex] = updatedFile;
      console.log("new files: ", newFiles);
      //@ts-ignore
      setMarkers((prev) => [...prev, marker]);
      //@ts-ignore
      //setFiles(newFiles);
      //
    };

    const openProductsModal = (handlers: {
      open: Function;
      close: Function;
      toggle: Function;
    }) => {
      handlers.open();
    };

    const toggle45 = () => {
      if (imgRef.current) {
        const { width, height } = imgRef.current;
        setAspect(4 / 5);
        setCrop(centerAspectCrop(width, height, 4 / 5));
      }
    };

    const toggleOriginal = () => {
      if (imgRef.current) {
        const { width, height } = imgRef.current;
        setAspect(width / height);
        setCrop(centerAspectCrop(width, height, width / height));
      }
    };

    const toggle169 = () => {
      if (imgRef.current) {
        const { width, height } = imgRef.current;
        setAspect(16 / 9);
        setCrop(centerAspectCrop(width, height, 16 / 9));
      }
    };

    const showCroppedImageTest = useCallback(async () => {
      try {
        const croppedImage = await getCroppedImg(
          item.src,
          croppedAreaPixels,
          rotation
        );
        //@ts-ignore
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    }, [croppedAreaPixels, rotation]);
    console.log("checking item: ", item);
    console.log("cropped image: ", croppedImage);
    const child: ReactNode = (
      <div
        className={cx(classes.item, {
          [classes.itemDragging]: snapshot.isDragging,
        })}
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div className={classes.symbol}>
            <AspectRatio ratio={720 / 1080} maw={300} mx="auto">
              <Image src={item.src} alt={"Panda"} />
            </AspectRatio>
          </div>
          <div style={{ marginLeft: 10 }}>
            <Text>{item.fileName}</Text>
            <div style={{ marginTop: 10 }}>
              <Button onClick={open}>Add Tags</Button>
              <Button
                onClick={imageResizeHandlers.open}
                style={{ marginLeft: 10 }}
              >
                Edit Image
              </Button>
              {/* <Button onClick={() => setIsOpen(true)}>Open modal</Button> */}
              {/* <MemoizedModal
                //@ts-ignore
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalState={modalState}
                setModalState={setModalState}
                item={item}
              /> */}

              <Modal
                transitionProps={{ transition: "fade", duration: 0 }}
                fullScreen
                centered
                opened={imageResizeOpened}
                onClose={closeImageResizerModal}
                title="Resize the selected image."
              >
                <div>
                  <div className="crop-container">
                    <Cropper
                      initialCroppedAreaPercentages={initialCroppedArea}
                      initialCroppedAreaPixels={croppedAreaPixels}
                      restrictPosition={true}
                      image={item.src}
                      crop={crop}
                      zoom={zoom}
                      aspect={aspect}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                  <div className="controls">
                    <input
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        //@ts-ignore
                        setZoom(e.target.value);
                      }}
                      className="zoom-range"
                    />
                    <Center style={{ width: "100%" }}>
                      {images && images.length === 1 ? (
                        <Button onClick={toggleOriginal}>Original</Button>
                      ) : null}
                      <Space w="sm" />
                      <Button
                        style={{
                          backgroundColor:
                            aspect === 4 / 5 ? "lightgreen" : "#4287f5",
                        }}
                        onClick={() => setAspect(4 / 5)}
                      >
                        4:5
                      </Button>
                      <Space w="sm" />
                      <Button
                        style={{
                          backgroundColor:
                            aspect === 16 / 9 ? "lightgreen" : "#4287f5",
                        }}
                        onClick={() => setAspect(16 / 9)}
                      >
                        16:9
                      </Button>
                    </Center>
                    <img src={output} />
                    {/* <Button onClick={showCroppedImageTest}>Show result</Button> */}
                  </div>
                </div>
                {/* <ReactCrop
                  locked
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={globalAspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={item.src}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop> */}
                {/* <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                >
                  <Cropper
                    image={item.src}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div> */}
                {/* <Space h="xs" />
                <Center style={{ width: "100%" }}>
                  <Slider
                    //@ts-ignore
                    defaultValue={1}
                    min={1}
                    value={scale}
                    onChange={(e) => setScale(Number(e))}
                    thumbSize={26}
                    styles={{ thumb: { borderWidth: rem(2), padding: rem(3) } }}
                    thumbChildren={<IconZoomIn size={60} strokeWidth={3} />}
                    style={{ width: "50%" }}
                  />
                </Center>
                <Space h="md" />
                <Center style={{ width: "100%" }}>
                  {images && images.length === 1 ? (
                    <Button onClick={toggleOriginal}>Original</Button>
                  ) : null}

                  <Space w="sm" />
                  <Button onClick={toggle45}>4:5</Button>
                  <Space w="sm" />
                  <Button onClick={toggle169}>16:9</Button>
                </Center> */}
              </Modal>
              <Modal
                fullScreen
                style={{ height: "100%" }}
                opened={opened}
                onClose={closeTagsModal}
                title="Click anywhere on the image to add a tag. Tags will be saved upon closing."
              >
                <AspectRatio ratio={4 / 5} maw={500} mx="auto">
                  <div style={{ border: "1px solid lightgray" }}>
                    <ImageMarker
                      onAddMarker={(marker) =>
                        onAddMarker(marker, products, item.fileName)
                      }
                      markerComponent={(props: MarkerComponentProps) => (
                        //@ts-ignore
                        <CustomMarker
                          //@ts-ignore
                          markers={markers}
                          setMarkers={setMarkers}
                          markerData={props}
                        />
                      )}
                      //@ts-ignore
                      src={
                        updatedFile.hasOwnProperty("blobUrl")
                          ? //@ts-ignore
                            updatedFile["blobUrl"]
                          : item.src
                      }
                      markers={markers}
                    />
                  </div>
                </AspectRatio>
                <Center style={{ marginTop: 10 }}>
                  <Button
                    disabled={!(markers && markers.length > 0)}
                    onClick={() => setMarkers([])}
                  >
                    Reset
                  </Button>
                  <Space w="md" />
                  <Button
                    disabled={!(markers.length > 0)}
                    //@ts-ignore
                    onClick={() => setMarkers((prev) => prev.slice(0, -1))}
                  >
                    Remove Last
                  </Button>
                  <Space w="md" />
                  <Button onClick={closeTagsModal}>Close</Button>
                </Center>
              </Modal>
            </div>
          </div>
        </div>
        <div {...provided.dragHandleProps} className={classes.dragHandle}>
          <IconGripVertical size="1.05rem" stroke={1.5} />
        </div>
      </div>
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
  };

  const items = state.map((item, index) => (
    <Draggable key={item.fileName} index={index} draggableId={item.fileName}>
      {(provided, snapshot) => (
        <PortalAwareItem
          //@ts-ignore
          updateCurrentSetupFiles={props.updateCurrentSetupFiles}
          images={files}
          setImages={setFiles}
          products={products}
          image={item}
          provided={provided}
          snapshot={snapshot}
        />
      )}
    </Draggable>
  ));

  const loadFilesIntoWindow = (incomingFiles: []) => {
    console.log("accepted files", incomingFiles);
    let filesCopy = [...files];

    incomingFiles.forEach((file, index) => {
      //@ts-ignore
      let url = URL.createObjectURL(file);
      console.log("currentImages: ", files);
      //@ts-ignore
      let fileName = file.name;
      let fileObject = {
        fileName: fileName,
        id: Math.random().toString(36).slice(2),
        src: url,
        markers: [],
        rawFile: file,
      };
      filesCopy.push(fileObject);
    });
    //@ts-ignore
    handlers.setState(filesCopy);
    //@ts-ignore
    props.updateCurrentSetupFiles(filesCopy);
    setFiles(filesCopy);
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <Dropzone
          onDrop={loadFilesIntoWindow}
          onReject={(files) => console.log("rejected files", files)}
          openRef={openRef}
          className={classes.dropzone}
          radius="md"
          accept={[MIME_TYPES.jpeg, MIME_TYPES.png]}
          maxSize={30 * 1024 ** 2}
        >
          <div style={{ pointerEvents: "none" }}>
            <Group position="center">
              <Dropzone.Accept>
                <IconDownload
                  size={rem(50)}
                  color={theme.colors[theme.primaryColor][6]}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  size={rem(50)}
                  color={theme.colors.red[6]}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconCloudUpload
                  size={rem(50)}
                  color={
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[0]
                      : theme.black
                  }
                  stroke={1.5}
                />
              </Dropzone.Idle>
            </Group>

            <Text ta="center" fw={700} fz="lg" mt="xl">
              <Dropzone.Accept>Drop files here</Dropzone.Accept>
              <Dropzone.Reject>.jpg/.png files only</Dropzone.Reject>
              <Dropzone.Idle>Upload setup images</Dropzone.Idle>
            </Text>
            <Text ta="center" fz="sm" mt="xs" c="dimmed">
              Drag&apos;n&apos;drop files here to upload. We only accept{" "}
              <i>.png/.jpg</i> files that are less than 30mb in size.
            </Text>
          </div>
        </Dropzone>

        <Button
          className={classes.control}
          size="md"
          radius="xl"
          onClick={() => openRef.current?.()}
        >
          Select files
        </Button>
      </div>
      <Text c="dimmed" style={{ paddingBottom: 5 }}>
        It's recommended that you add products before adding tags to images.
      </Text>
      <DragDropContext
        enableDefaultSensors={false}
        sensors={[useMouseSensor, useKeyboardSensor, useTouchSensor]}
        onDragEnd={({ destination, source }) =>
          handlers.reorder({
            from: source.index,
            to: destination?.index || 0,
          })
        }
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

//  <Carousel
//    getEmblaApi={setEmbla}
//    withIndicators
//    mx="auto"
//    align={"center"}
//    slideSize={"100%"}
//    slideGap={15}
//    sx={(theme) => ({
//      width: "100%",
//      border: "0px solid red",
//      backgroundImage: theme.fn.gradient({
//        from: "blue",
//        to: "#57d8ff",
//        deg: 0,
//      }),
//    })}
//    styles={{
//      control: {
//        "&[data-inactive]": {
//          opacity: 0,
//          cursor: "default",
//        },
//      },
//      indicator: {
//        width: rem(12),
//        height: rem(4),
//        transition: "width 250ms ease",

//        "&[data-active]": {
//          width: rem(40),
//        },
//      },
//    }}
//  >
//    {files.map((image, index) => {
//      return (
//        <Carousel.Slide key={index} style={{}}>
//          <AspectRatio ratio={4 / 5} maw={700} mx="auto">
//            {/* <Image
//                     withPlaceholder
//                     src={image}
//                     height={"100%"}
//                     width={"100%"}
//                     alt="Norway"
//                   /> */}
//            <div style={{ border: "0px solid red" }}>
//              <div style={{ border: "0px solid green" }}>
//                <ImageMarker src={image} markers={[{ top: 20, left: 20 }]} />
//              </div>
//            </div>
//          </AspectRatio>
//        </Carousel.Slide>
//      );
//    })}
//  </Carousel>;
