import { Text, Title, TextInput, Button, Image, Space } from "@mantine/core";
import classes from "./EmailBanner.module.css";
import { Widget } from "@typeform/embed-react";

export function EmailBanner() {
  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <Title className={classes.title}>
          Big updates coming to Desk Setup Builder!
        </Title>
        <Space h="md" />
        <Text fw={500} fz="lg" mb={5}>
          Contact: desksetupbuilder@gmail.com
        </Text>
        <Text fz={"xl"} fw={500} mb={5}>
          Get the latest updates by:
        </Text>
        <Text
          component="a"
          style={{ color: "#4287f5", textDecoration: "underline" }}
          href="https://x.com/desksetupbuildr"
          fw={500}
          fz="lg"
          mb={5}
        >
          Following @desksetupbuildr on Twitter
        </Text>
        <br />
        <Text
          component="a"
          style={{ color: "#4287f5", textDecoration: "underline" }}
          href="https://forms.gle/JvybWENbQEDk3w8k7"
          fw={500}
          fz="lg"
          mb={5}
        >
          Sign up for updates with your email
        </Text>
      </div>
    </div>
  );
}
