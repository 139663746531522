import {
  createStyles,
  Text,
  Container,
  ActionIcon,
  Group,
  Image,
  rem,
  Modal,
  Switch,
  Button,
  Space,
} from "@mantine/core";
import { IconBrandInstagram } from "@tabler/icons-react";
import DSB from "./DSBLOGO.webp";
import { Link } from "react-router-dom";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import { Accordion, AccordionControlProps, Box } from "@mantine/core";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    maxWidth: rem(200),

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  description: {
    marginTop: rem(5),

    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
      textAlign: "center",
    },
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  groups: {
    display: "flex",
    flexWrap: "wrap",

    [theme.fn.smallerThan("sm")]: {
      textAlign: "center",
      marginTop: 30,
    },
  },

  wrapper: {
    width: rem(160),
  },

  link: {
    cursor: "pointer",
    display: "block",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    paddingTop: rem(15),
    paddingBottom: rem(15),
    [theme.fn.smallerThan("md")]: {
      paddingTop: rem(15),
      paddingBottom: rem(15),
    },
    [theme.fn.largerThan("sm")]: {
      paddingTop: rem(3),
      paddingBottom: rem(3),
    },
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  afterFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(5)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  social: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

interface FooterLinksProps {
  data: {
    title: string;
    links: { label: string; link: string }[];
  }[];
}

export function FooterLinks({ data }: FooterLinksProps) {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component={Link}
        to={link.link}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
        <a
          href={"#"}
          className={classes.link}
          onClick={() => {
            //@ts-ignore
            window.CC.showSettings(0);
          }}
        >
          Cookie Preferences
        </a>
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          {/* <MantineLogo size={30} /> */}
          <Group>
            <Image
              maw={40}
              height={"40px"}
              width={"40px"}
              radius="md"
              src={DSB}
              alt="Random image"
            />
            <Text style={{ maxWidth: 100 }}>Desk Setup Builder</Text>
          </Group>
        </div>
        <div className={classes.groups}>{groups}</div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text color="dimmed" size="sm">
          © 2023 Desk Setup Builder
        </Text>

        <Group spacing={0} className={classes.social} position="right" noWrap>
          <ActionIcon
            aria-label="desk setup builder instagram page link"
            onClick={() =>
              window.open(
                "https://www.instagram.com/desksetupbuilder/",
                "_blank"
              )
            }
            size="lg"
          >
            <IconBrandInstagram size="3.05em" stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
}
