import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Group,
  Box,
  Image,
  Text,
  Title,
  Center,
  Space,
} from "@mantine/core";
import { useNavigate } from "react-router";

export default function ArticleModal(props: {}) {
  const [opened, { close }] = useDisclosure(true);
  const navigate = useNavigate();

  const closeModal = () => {
    close();
    navigate("/blog");
  };

  return (
    <Modal centered opened={opened} onClose={closeModal} title="">
      <Box maw={400} mx="auto">
        <Image
          radius="md"
          src="https://kawaiilabs.s3.amazonaws.com/good-desk-setup/good-desk-setup-card.jpg"
          alt="A desk setup"
          caption={
            <div style={{ display: "flex", justifyContent: "center " }}>
              <Text>
                Photo by{" "}
                <a href="https://unsplash.com/@rpnickson?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                  Roberto Nickson
                </a>{" "}
                on{" "}
                <a href="https://unsplash.com/photos/fl2myUOVYzA?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                  Unsplash
                </a>
              </Text>
            </div>
          }
        />
        <Space h="md" />
        <Center style={{ display: "block" }}>
          <Text
            variant="gradient"
            gradient={{ from: "indigo", to: "cyan", deg: 45 }}
            sx={{ fontFamily: "Greycliff CF, sans-serif" }}
            ta="start"
            fz="xl"
            fw={700}
          >
            What makes a great desk setup?
          </Text>
          <Space h="md" />
          <Text fw={350} style={{ whiteSpace: "normal" }} ta="start">
            The dawn of the remote work era has emphasized the significance of a
            well-organized and comfortable home office desk setup. With
            countless hours spent working, gaming, studying, or creating, it's
            crucial to establish a workspace that boosts productivity, maintains
            your health, and fuels your creativity. But, what truly makes a
            great desk setup? Let's unravel the essential elements that comprise
            an ideal workspace.
          </Text>
          <Space h="md" />
          <Text
            variant="gradient"
            gradient={{ from: "orange", to: "green", deg: 45 }}
            fw={700}
          >
            Element 1: Ergonomic Furniture
          </Text>
          <Space h="md" />
          <Image
            radius="md"
            src="https://www.blitzresults.com/wp-content/uploads/ergonomic-sitting-EN.jpg"
            alt="An infographic that shows a guide for optimal desk and chair height"
            caption={
              <div style={{ display: "flex", justifyContent: "center " }}>
                <Text>
                  Infographic from Tim Lilling on{" "}
                  <a href="https://www.blitzresults.com/en/ergonomic/">
                    blitzresults.com
                  </a>
                </Text>
              </div>
            }
          />
          <Space h="md" />
          <Text fw={350} style={{ whiteSpace: "normal" }} ta="start">
            At the core of any effective desk setup is ergonomic furniture. The
            right desk and chair combination can significantly impact your
            posture, comfort, and overall health. The ideal desk height allows
            you to place your computer monitor at eye level, reducing neck
            strain. An ergonomic chair should provide adequate lumbar support
            and promote a neutral body position to minimize back pain.
            Adjustable elements, like chair height or desk width, offer
            customizability to fit your unique body shape and working style.
          </Text>
          <Space h="md" />
          <Text
            variant="gradient"
            gradient={{ from: "orange", to: "green", deg: 45 }}
            fw={700}
          >
            Element 2: Tech
          </Text>
          <Space h="md" />
          <Text fw={350} style={{ whiteSpace: "normal" }} ta="start">
            This may seem obvious but technology is a critical component of any
            setup. Whether it's a high-resolution monitor, a mechanical
            keyboard, a precision mouse, or a desktop computer with superb
            processing power, the quality of your tech gear can make or break
            your productivity levels. Choosing devices with the right
            specifications for your tasks can ensure smoother work sessions and
            an enhanced user experience. There is a balance between cost and
            what you really need/want to get the job done.
          </Text>
          <Space h="md" />
          <Text
            variant="gradient"
            gradient={{ from: "orange", to: "green", deg: 45 }}
            fw={700}
          >
            Element 3: Effective Lighting
          </Text>
          <Space h="md" />
          <Image
            radius="md"
            src="https://kawaiilabs.s3.amazonaws.com/Forest+Lights-1687025124650/forest-lights-compressed-1.webp"
            alt="A clean desk setup that uses warm lighting and individual lights"
            caption={
              <div style={{ display: "flex", justifyContent: "center " }}>
                <Text>
                  Photo from{" "}
                  <a href="https://www.instagram.com/7cvndy/">@7cvndy</a>
                </Text>
              </div>
            }
          />
          <Space h="md" />
          <Text fw={350} style={{ whiteSpace: "normal" }} ta="start">
            Lighting plays an understated, yet critical role in creating a
            productive workspace. Natural light is always preferable as it helps
            maintain a natural sleep-wake cycle and reduces eye strain. However,
            not every desk setup can harness the power of sunlight. In such
            cases, a well-placed desk lamp with adjustable brightness can
            provide sufficient lighting, especially for tasks requiring high
            visual precision.
          </Text>
          <Space h="md" />
          <Text
            variant="gradient"
            gradient={{ from: "orange", to: "green", deg: 45 }}
            fw={700}
          >
            Element 4: Organization and Cable Management
          </Text>
          <Space h="md" />
          <Text fw={350} style={{ whiteSpace: "normal" }} ta="start">
            A clutter-free environment leads to a clutter-free mind. Efficient
            organization and cable management are vital for maintaining a clean,
            distraction-free desk setup. Utilize cable ties, trays, or sleeves
            neatly to most desks and can easily fit a power strip. Additionally,
            to keep your wires in check. Here is one such{" "}
            <a href="https://amzn.to/3pn9BXs">tray</a> that attaches neatly to
            most desks and can easily fit a power strip. Additionally, consider
            using desk organizers or drawers to store your stationary,
            notebooks, and other essentials systematically.
          </Text>
          <Space h="md" />
          <Text
            variant="gradient"
            gradient={{ from: "orange", to: "green", deg: 45 }}
            fw={700}
          >
            Element 5: Personalization
          </Text>
          <Space h="md" />
          <Image
            radius="md"
            src="https://kawaiilabs.s3.amazonaws.com/kuromi/kuromi-purple-1_gwmple-card-image-13-2.webp"
            alt="A desk setup that has a purple color scheme and features sanrio characters as the main theme."
            caption={
              <div style={{ display: "flex", justifyContent: "center " }}>
                <Text>
                  Photo from{" "}
                  <a href="https://www.instagram.com/afkregina/">@afkregina</a>
                </Text>
              </div>
            }
          />
          <Space h="md" />
          <Text fw={350} style={{ whiteSpace: "normal" }} ta="start">
            While functionality is crucial, personalization gives your desk
            setup its unique flair. Inject your personality into your workspace
            with elements like artworks, plant life, personalized mouse pads, or
            even a mug from your favorite series. Remember, your desk setup is
            your personal space, and it should feel inviting and inspire
            creativity.
          </Text>
          <Space h="md" />
          <Text
            variant="gradient"
            gradient={{ from: "indigo", to: "green", deg: 45 }}
            fw={700}
          >
            Conclusion
          </Text>
          <Space h="md" />
          <Text fw={350} style={{ whiteSpace: "normal" }} ta="start">
            Creating the perfect desk setup isn't just about aesthetics; it's a
            balance of comfort, functionality, and personal style. With the
            right ergonomic furniture, superior tech gear, effective lighting,
            neat organization, and a touch of personality, your desk setup can
            become a powerful tool in enhancing productivity and maintaining
            your physical and mental wellbeing. Remember, everyone's ideal setup
            is unique. Experiment with different configurations and components
            until you find the desk setup that matches your needs and style the
            best. Happy organizing!
          </Text>
        </Center>
      </Box>
    </Modal>
  );
}
