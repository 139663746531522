import { useEffect, useRef, useState } from "react";
import React from "react";
import {
  AppShell,
  Header,
  Text,
  Burger,
  useMantineTheme,
  ColorSchemeProvider,
  ColorScheme,
  MantineProvider,
  ActionIcon,
  createStyles,
  Image,
  rem,
  Group,
  Center,
  Grid,
  Drawer,
  Button,
  Stack,
  Modal,
  Tabs,
  TextInput,
  Checkbox,
  Paper,
  Col,
  Card,
  Space,
  Affix,
  Transition,
  MediaQuery,
  Title,
} from "@mantine/core";
import {
  IconSun,
  IconMoonStars,
  IconBrandInstagram,
  IconMessageCircle,
  IconSettings,
  IconCloudUpload,
  IconTrash,
  IconArticle,
  IconDeviceDesktop,
} from "@tabler/icons-react";
import DSB from "./DSBLOGO.webp";
import SetupCard from "./card";
import { FooterLinks } from "./footer";
import { useDisclosure, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { ArticleCard } from "./articleCard";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import SetupCardModal from "./modal";
import PrivacyPolicy from "./privacypolicy";
import AffiliateDisclosure from "./affiliatedisclosure";
import Contact from "./contact";
import { DropzoneButton } from "./dropzone";
import SetupForm from "./addsetupform";
import StepWizard from "./stepper";
import { useForm } from "@mantine/form";
import AWS from "aws-sdk";
import setups from "./setups.json";
import ReactGA from "react-ga4";
import CookiePolicy from "./cookiepolicy";
import GoogleAnalytics from "./googleanalytics";
import CookieConsentComponent from "./CookieConsentComponent";
import "./App.css";
import ArticleModal from "./articleCardModal";
import { EmailBanner } from "./EmailBanner";

AWS.config.update({
  accessKeyId: "AKIAXNBX7OS4BFZVBF6M",
  secretAccessKey: "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",
  region: "us-east-1",
  signatureVersion: "v4",
});

const useStyles = createStyles((theme) => ({
  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
  topTabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  links: {
    width: rem(260),

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
  title: {
    color: "#858585",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.05,
    fontSize: rem(27),
    marginBottom: 20,
    textAlign: "center",
    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      fontSize: rem(20),
      lineHeight: 1.15,
      textAlign: "center",
    },
  },
  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkActive: {
    "&, &:hover": {
      background: theme.fn.gradient({
        from: "blue",
        to: "teal",
        deg: 20,
      }),
      color: "white",
    },
  },

  bottomNavLink: {
    width: "100%",
    display: "block",
    padding: `${rem(5)} ${rem(12)}`,
    borderRadius: 0,
    textDecoration: "none",
    color: theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  bottomNavLinkActive: {
    color: "white",
    "&, &:hover": {
      background: theme.fn.gradient({
        from: "blue",
        to: "teal",
        deg: 20,
      }),
      color: "white",
    },
  },

  setupsGrid: { justifyContent: "center" },
}));

// const setups = [
//   {
//     link: "/setup/space-arcade",
//     name: "Space Arcade",
//     products: [],
//     instagramLink: "",
//     images: [
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/Space+Arcade-1687025314206/space-arcade-compressed.jpg",
//         markers: [],
//       },
//     ],
//   },
//   {
//     link: "/setup/forest-lights",
//     name: "Forest Lights",
//     products: [],
//     instagramLink: "https://www.instagram.com/p/Ctpj5vurWw1",
//     images: [
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/Forest+Lights-1687025124650/forest-lights-compressed-1.webp",
//         markers: [],
//       },
//     ],
//   },
//   {
//     link: "/setup/mounted-pc-setup",
//     name: "Mounted PC Setup",
//     products: [
//       {
//         name: "MX Vertical Mouse",
//         link: "https://amzn.to/3JGSuqH",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "FRL84 Keyboard w/ Custom Case",
//         link: "https://p3dstore.com/products/frl84-invisibolt-case-and-pcb-group-buy",
//         retailer: "P3DStore",
//         price: "",
//       },
//       {
//         name: "CU7 Keyboard",
//         link: "https://www.raymont-osman.com/capsunlocked-cu7-keyboard/",
//         retailer: "Raymont Osman",
//         price: "",
//       },
//       {
//         name: "Steelcase Leap V1 Chair",
//         link: "https://www.steelcase.com/products/office-chairs/leap/",
//         retailer: "Steelcase",
//         price: "",
//       },
//       {
//         name: "Cloud Marble Liquid Deskmat",
//         link: "https://kineticlabs.com/desk-mats/agile/marble-liquid-mat",
//         retailer: "kinetic labs",
//         price: "",
//       },
//       {
//         name: "Milled Acrylic Artisan Box",
//         link: "https://p3dstore.com/products/milled-acrylic-10x10-artisan-box",
//         retailer: "P3DStore",
//         price: "",
//       },
//     ],
//     instagramLink: "",
//     images: [
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/Mounted+PC+Setup-1687017501237/mounted-pc-setup-1.webp",
//         markers: [
//           {
//             top: 91.6091024253263,
//             left: 88.65461847389558,
//             tableRowId: "4",
//           },
//           {
//             top: 89.68139158195281,
//             left: 72.59036144578313,
//             tableRowId: "0",
//           },
//           {
//             top: 84.54082933295683,
//             left: 61.74698795180723,
//             tableRowId: "1",
//           },
//           {
//             top: 69.76171286709338,
//             left: 37.04819277108434,
//             tableRowId: "2",
//           },
//           {
//             top: 79.07898194339859,
//             left: 20.180722891566266,
//             tableRowId: "3",
//           },
//           {
//             top: 72.65327913215361,
//             left: 71.5863453815261,
//             tableRowId: "5",
//           },
//         ],
//       },
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/Mounted+PC+Setup-1687017501237/qskdqof3f35b1.webp",
//         markers: [],
//       },
//     ],
//   },
//   {
//     link: "/setup/calm-purple",
//     name: "Calm Purple",
//     products: [
//       {
//         name: "Stream Deck Mini",
//         link: "https://amzn.to/3NfX689",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "QcK Prism Cloth RGB Mousepad ",
//         link: "https://amzn.to/3Nb5zte",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Glorious GMMK TKL",
//         link: "https://www.gloriousgaming.com/products/gmmk-tkl-tenkeyless-brown-switch",
//         retailer: "Glorious",
//         price: "",
//       },
//       {
//         name: "Logitech G305 Wireless",
//         link: "https://amzn.to/42TcNrG",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Sony Speakers",
//         link: "https://amzn.to/3Pi2x9d",
//         retailer: "Amazon",
//         price: "",
//       },
//     ],
//     instagramLink: "",
//     images: [
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/calm-purple/calm-purple-1.webp",
//         markers: [
//           {
//             top: 49.424478829117916,
//             left: 24.799196787148595,
//             fileName: "1acggy6hut4b1.png",
//             tableRowId: "4",
//           },
//           {
//             top: 77.43317581204606,
//             left: 29.016064257028113,
//             fileName: "1acggy6hut4b1.png",
//             tableRowId: "1",
//           },
//           {
//             top: 76.66581425086996,
//             left: 35.24096385542169,
//             fileName: "1acggy6hut4b1.png",
//             tableRowId: "2",
//           },
//           {
//             top: 78.20053737322218,
//             left: 54.31726907630522,
//             fileName: "1acggy6hut4b1.png",
//             tableRowId: "3",
//           },
//           {
//             top: 68.2248370779327,
//             left: 63.55421686746988,
//             fileName: "1acggy6hut4b1.png",
//             tableRowId: "0",
//           },
//         ],
//       },
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/calm-purple/calm-purple-2.webp",
//         markers: [],
//       },
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/calm-purple/calm-purple-3.webp",
//         markers: [],
//       },
//     ],
//   },
//   {
//     link: "/setup/plants-overhead",
//     name: "Plants Overhead",
//     products: [
//       {
//         name: "LG 27GP950-B 4K 160Hz IPS",
//         link: "https://amzn.to/43ZlVMN",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "BenQ Zowie XL2566K",
//         link: "https://amzn.to/3PbN5vc",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "GWolves HTX 4K Wireless Gaming Mouse ",
//         link: "https://amzn.to/443PQ5X",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Artisan Zero FX XSoft Orange",
//         link: "https://amzn.to/3NfaWr4",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "KBDfans Aluminum Case 5 Degree",
//         link: "https://kbdfans.com/collections/five-degree",
//         retailer: "KBDfans",
//         price: "",
//       },
//       {
//         name: "Steelseries Arctis Nova Pro Wireless",
//         link: "https://amzn.to/463veN7",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Herman Miller Aeron",
//         link: "https://amzn.to/42QRMgV",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Fractal Design North",
//         link: "https://amzn.to/3qS8yPO",
//         retailer: "Amazon",
//         price: "",
//       },
//     ],
//     instagramLink:
//       "https://www.instagram.com/p/CtpIbqzryAu/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
//     images: [
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/Plants+Overhead-1686960366413/plants-overhead-1.jpg",
//         markers: [
//           {
//             top: 73.71987951807229,
//             left: 56.726907630522085,
//             fileName: "8ouwua7q0g4b1.jpg",
//             tableRowId: "2",
//           },
//           {
//             top: 75.37650602409639,
//             left: 69.57831325301204,
//             fileName: "8ouwua7q0g4b1.jpg",
//             tableRowId: "3",
//           },
//           {
//             top: 47.816265060240966,
//             left: 78.6144578313253,
//             fileName: "8ouwua7q0g4b1.jpg",
//             tableRowId: "7",
//           },
//           {
//             top: 45.4066265060241,
//             left: 61.74698795180723,
//             fileName: "8ouwua7q0g4b1.jpg",
//             tableRowId: "0",
//           },
//           {
//             top: 53.38855421686747,
//             left: 47.088353413654616,
//             fileName: "8ouwua7q0g4b1.jpg",
//             tableRowId: "1",
//           },
//           {
//             top: 85.76807228915662,
//             left: 15.160642570281125,
//             fileName: "8ouwua7q0g4b1.jpg",
//             tableRowId: "4",
//           },
//           {
//             top: 85.76807228915662,
//             left: 90,
//             fileName: "8ouwua7q0g4b1.jpg",
//             tableRowId: "6",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     link: "/setup/Sleek-and-Minimalist",
//     name: "Sleek and Minimalist",
//     products: [
//       {
//         name: "Samsung Odyssey G70A",
//         link: "https://amzn.to/3Cwvu9N",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Logitech G Pro X Superlight",
//         link: "https://amzn.to/3Jemcmj",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Razer Huntsman Mini",
//         link: "https://amzn.to/3p8Dfj6",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Edifier Luna Eclipse E25HD",
//         link: "https://amzn.to/446etPA",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Corsair Void Elite Wireless",
//         link: "https://amzn.to/448IoXn",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Ikea UTESPELARE",
//         link: "https://amzn.to/3Jl6yFT",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "Ikea Skadis",
//         link: "https://amzn.to/3Nc1HYW",
//         retailer: "Amazon",
//         price: "",
//       },
//       {
//         name: "KERDOM Ergonomic Chair 9070",
//         link: "https://amzn.to/42EVWbO",
//         retailer: "Amazon",
//         price: "",
//       },
//     ],
//     instagramLink: "https://www.instagram.com/p/Ctmjph8LamS",
//     images: [
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/Sleek+and+Minimalist-1686952609234/sleek-and-minimalist-1.jpg",
//         markers: [
//           {
//             top: 42.906219597643435,
//             left: 34.036144578313255,
//             tableRowId: "0",
//           },
//           {
//             top: 49.86615508674799,
//             left: 45.08032128514056,
//             tableRowId: "1",
//           },
//           {
//             top: 55.21995161682842,
//             left: 42.06827309236948,
//             tableRowId: "2",
//           },
//           {
//             top: 68.60444294202948,
//             left: 44.678714859437754,
//             tableRowId: "5",
//           },
//           {
//             top: 54.14919231081233,
//             left: 24.397590361445783,
//             tableRowId: "3",
//           },
//           {
//             top: 28.18327913992226,
//             left: 11.546184738955823,
//             tableRowId: "6",
//           },
//           {
//             top: 19.349514865289557,
//             left: 18.17269076305221,
//             tableRowId: "4",
//           },
//           {
//             top: 49.86615508674799,
//             left: 56.12449799196787,
//             tableRowId: "7",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     link: "/setup/fall-vibes",
//     name: "Fall Vibes",
//     instagramLink: "https://www.instagram.com/p/CtAJaX0xQv9/",
//     images: [
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/animesetup/1rllmciswf3b1.jpeg",
//         markers: [
//           {
//             top: 50, //10% of the image relative size from top
//             left: 20, //50% of the image relative size from left
//             tableRowId: "1",
//           },
//           {
//             top: 50, //10% of the image relative size from top
//             left: 82, //50% of the image relative size from left
//             tableRowId: "1",
//           },
//           {
//             top: 55, //10% of the image relative size from top
//             left: 60, //50% of the image relative size from left
//             tableRowId: "0",
//           },
//           {
//             top: 80, //10% of the image relative size from top
//             left: 65, //50% of the image relative size from left
//             tableRowId: "2",
//           },
//           {
//             top: 76, //10% of the image relative size from top
//             left: 83, //50% of the image relative size from left
//             tableRowId: "3",
//           },
//           {
//             top: 90, //10% of the image relative size from top
//             left: 43, //50% of the image relative size from left
//             tableRowId: "5",
//           },
//           {
//             top: 80, //10% of the image relative size from top
//             left: 35, //50% of the image relative size from left
//             tableRowId: "4",
//           },
//           {
//             top: 90, //10% of the image relative size from top
//             left: 83, //50% of the image relative size from left
//             tableRowId: "6",
//           },
//         ],
//       },
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/animesetup/lbvg55qswf3b1.jpeg",
//         markers: [],
//       },
//     ],
//     products: [
//       {
//         name: "Acer Predator X34",
//         price: "899.99",
//         link: "https://amzn.to/43KzH5n",
//         retailer: "Amazon",
//       },
//       {
//         name: "LG UltraGear QHD GL-850",
//         price: "266.99",
//         link: "https://amzn.to/3OTuiV6",
//         retailer: "Amazon",
//       },
//       {
//         name: "MelGeek Mojo68",
//         price: "190",
//         link: "https://www.melgeek.com/products/melgeek-mojo68-plastic-see-through-custom-programmable-mechanical-keyboard?variant=43643795341566",
//         retailer: "Melgeek",
//       },
//       {
//         name: "Razer Viper V2 Pro",
//         price: "149.99",
//         link: "https://amzn.to/3OSRanK",
//         retailer: "Amazon",
//       },
//       {
//         name: "Audioengine A2+ Wireless Bluetooth Computer Speakers",
//         price: "269",
//         link: "https://amzn.to/45Lw3JZ",
//         retailer: "Amazon",
//       },
//       {
//         name: "IKEA KARLBY Countertop",
//         price: "249",
//         link: "https://www.ikea.com/us/en/p/karlby-countertop-walnut-veneer-50335208/",
//         retailer: "IKEA",
//       },
//       {
//         name: "IKEA ALEX Drawer Unit",
//         price: "249",
//         link: "https://www.ikea.com/us/en/p/alex-drawer-unit-white-00473546/",
//         retailer: "IKEA",
//       },
//     ],
//   },
//   {
//     link: "/setup/kuromi",
//     name: "Kuromi Purple",
//     instagramLink: "https://www.instagram.com/desksetupbuilder/",
//     images: [
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/kuromi/kuromi-purple-1.webp",
//         markers: [
//           {
//             top: 65, //10% of the image relative size from top
//             left: 47, //50% of the image relative size from left
//             tableRowId: "0",
//           },
//           {
//             top: 72, //10% of the image relative size from top
//             left: 47, //50% of the image relative size from left
//             tableRowId: "1",
//           },
//           {
//             top: 70, //10% of the image relative size from top
//             left: 10, //50% of the image relative size from left
//             tableRowId: "2",
//           },
//           {
//             top: 50, //10% of the image relative size from top
//             left: 65, //50% of the image relative size from left
//             tableRowId: "3",
//           },
//           {
//             top: 70, //10% of the image relative size from top
//             left: 25, //50% of the image relative size from left
//             tableRowId: "4",
//           },
//           {
//             top: 55, //10% of the image relative size from top
//             left: 5, //50% of the image relative size from left
//             tableRowId: "5",
//           },
//           {
//             top: 33, //10% of the image relative size from top
//             left: 15, //50% of the image relative size from left
//             tableRowId: "6",
//           },
//           {
//             top: 50, //10% of the image relative size from top
//             left: 30, //50% of the image relative size from left
//             tableRowId: "7",
//           },
//           {
//             top: 40, //10% of the image relative size from top
//             left: 30, //50% of the image relative size from left
//             tableRowId: "8",
//           },
//           {
//             top: 42, //10% of the image relative size from top
//             left: 80, //50% of the image relative size from left
//             tableRowId: "9",
//           },
//         ],
//       },
//       {
//         src: "https://kawaiilabs.s3.amazonaws.com/kuromi/kuromi-purple-2.webp",
//         markers: [],
//       },
//     ],
//     products: [
//       {
//         name: "Xiaomi Smart Clock",
//         price: "899.99",
//         link: "https://www.mi.com/global/product/mi-smart-clock/",
//         retailer: "Xiaomi",
//       },
//       {
//         name: "Finalkey V65 R2 (White)",
//         price: "899.99",
//         link: "https://amzn.to/3quKlik",
//         retailer: "Amazon",
//       },
//       {
//         name: "Edifier R1280DB",
//         price: "899.99",
//         link: "https://amzn.to/43jC9Qg",
//         retailer: "Amazon",
//       },
//       {
//         name: "Behringer U-Phoria UM2 USB Audio Interface",
//         price: "899.99",
//         link: "https://amzn.to/3qugpCC",
//         retailer: "Amazon",
//       },
//       {
//         name: "8Bitdo Ultimate Bluetooth Controller",
//         price: "899.99",
//         link: "https://amzn.to/3IVrbbB",
//         retailer: "Amazon",
//       },
//       {
//         name: "Divoom Ditoo Plus in Black",
//         price: "899.99",
//         link: "https://amzn.to/3ITfCS4",
//         retailer: "Amazon",
//       },
//       {
//         name: "Fifine AM8",
//         price: "899.99",
//         link: "https://amzn.to/3qpw4TR",
//         retailer: "Amazon",
//       },
//       {
//         name: "Gigabyte G34WQC",
//         price: "899.99",
//         link: "https://amzn.to/43Lgd0t",
//         retailer: "Amazon",
//       },
//       {
//         name: "AOC 27B2H",
//         price: "899.99",
//         link: "https://amzn.to/3CagA8P",
//         retailer: "Amazon",
//       },
//       {
//         name: "Hyte Y60",
//         price: "899.99",
//         link: "https://amzn.to/42hpmws",
//         retailer: "Amazon",
//       },
//     ],
//   },
// ];

export default function AppShellDemo(props: Partial<DropzoneProps>) {
  const matches = useMediaQuery("(max-width: 56.25em)");
  const s3 = new AWS.S3();
  const links = [
    {
      label: "Setups",
      link: "/",
      icon: <IconDeviceDesktop />,
    },
    {
      label: "Blog",
      link: "/blog",
      icon: <IconArticle />,
    },
  ];

  const form = useForm({
    initialValues: {
      name: "",
      link: "",
      retailer: "",
    },

    validate: {
      name: (value) => (value && value.length > 0 ? null : "Invalid name"),
      link: (value) => (value && value.length > 0 ? null : "Invalid link"),
      retailer: (value) =>
        value && value.length > 0 ? null : "Invalid retailer",
    },
  });

  const setupForm = useForm({
    initialValues: {
      setupName: "",
      instagramLink: "",
    },

    validate: {
      setupName: (value) => (value && value.length > 0 ? null : "Invalid name"),
      instagramLink: (value) =>
        value && value.length > 0 ? null : "Invalid link",
    },
  });

  const location = useLocation();
  const previousLocation = location.state?.previousLocation || location;

  useEffect(() => {
    //@ts-ignore
    if (location.pathname.includes("setup")) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: "Setup Visit: " + location.pathname,
      });
    } else if (location.pathname.includes("blog")) {
      setActive("/blog");
    }
  }, [location.pathname]);

  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const dark = colorScheme === "dark";
  const { classes, cx } = useStyles();
  const [active, setActive] = useState(links[0].link);
  const [openedBurger, { toggle, close }] = useDisclosure(false);
  const [openAddModal, addHandlers] = useDisclosure(false);
  const [products, setProducts] = useState<[]>([]);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    setups.forEach((setup) => {
      setup.images.forEach((image) => {
        let img = document.createElement("img");
        img.src = image.src;
        //document.body.appendChild(img);
        //@ts-ignore
      });

      //let ratio;
      //let img = document.createElement("img");
      //@ts-ignore
      //img.src = setup.images[0].src;
      //@ts-ignore
      // if (img.complete) {
      //   if (img.naturalWidth > img.naturalHeight) {
      //     ratio = 1.91 / 1;
      //   } else if (img.naturalWidth < img.naturalHeight) {
      //     ratio = 4 / 5;
      //   } else {
      //     ratio = 4 / 5;
      //   }
      //   //@ts-ignore
      //   setup["ratio"] = ratio;
      // }
      //   // setup.images.forEach((image) => {
      //   //   let img = document.createElement("img");
      //   //   img.src = image.src;
      //   //   //@ts-ignore
      //   //   image["imageFile"] = img;
      //   // });
      // }
    });

    return () => {
      mounted.current = false;
    };
  }, []);

  const clickedDrawerButton = (index: number) => {
    toggle();
    setActive(links[index].link);
  };

  const items = links.map((link) => (
    <Link
      key={link.label}
      to={link.link}
      className={cx(classes.link, {
        [classes.linkActive]: active === link.link,
      })}
      onClick={(event) => {
        //event.preventDefault();
        setActive(link.link);
      }}
    >
      {link.label}
    </Link>
  ));

  const addProduct = (values: {}) => {
    //@ts-ignore
    values["price"] = "";
    //@ts-ignore
    setProducts((prev) => [...prev, values]);
    form.reset();
  };

  const [setupName, setSetupName] = useState("");
  const [instagramLink, setInstagramLink] = useState("");

  const addSetupName = (values: { setupName: string }) => {
    setSetupName(values.setupName.replaceAll(" ", "-"));
  };

  const updateSetupName = (e: {}) => {
    //@ts-ignore
    setSetupName(e.target.value);
  };

  const updateInstagramLink = (e: {}) => {
    //@ts-ignore
    setInstagramLink(e.target.value);
  };

  // const addSetupName = (values: { setupName: string }) => {
  //   console.log("values name: ", values);
  //   setSetupName(values.setupName.replaceAll(" ", "-"));
  // };

  const deleteProduct = (itemToDelete: {}) => {
    //@ts-ignore
    setProducts(products.filter((item) => item !== itemToDelete));
  };

  const closeSetupModal = () => {
    addHandlers.close();
    setProducts([]);
    setCurrentSetupFiles([]);
    form.reset();
  };

  const [currentSetupFiles, setCurrentSetupFiles] = useState([]);

  //useEffect(() => {}, [currentSetupFiles]);
  // const linksToc = [
  //   { label: "Introduction", link: "#introduction", order: 1 },
  //   { label: "Section 1", link: "#section1", order: 2 },
  //   { label: "Section 2", link: "#section2", order: 3 },
  //   { label: "Conclusion", link: "#conclusion", order: 4 },
  // ];

  const updateCurrentSetupFiles = (imageFiles: []) => {
    setCurrentSetupFiles(imageFiles);
  };

  const uploadToS3 = async () => {
    //@ts-ignore
    //let file = currentSetupFiles[0].rawFile;
    const promises = [];
    for (let i = 0; i < currentSetupFiles.length; i++) {
      //@ts-ignore
      let wholeFile = currentSetupFiles[i];
      //@ts-ignore
      let file = wholeFile.rawFile;
      if (!file) {
        continue;
      }

      let blob;

      //@ts-ignore
      if (wholeFile.hasOwnProperty("blobUrl")) {
        const response = await fetch(wholeFile["blobUrl"]);
        blob = await response.blob();
      }

      console.log("uploading blob: ", blob);
      console.log("uploading file: ", file);
      const params = {
        Bucket: "kawaiilabs/" + setupName,
        Key: `${Date.now()}-${file.name}`,
        //@ts-ignore
        Body: blob ? blob : file,
        ContentType: file.type,
      };
      console.log("params: ", params);
      promises.push(s3.upload(params).promise());
      //const { Location } = await s3.upload(params).promise();
    }
    //@ts-ignore
    Promise.all(promises)
      .then((values) => {
        //@ts-ignore
        const imageUrls = [];
        let currentSetupFilesCopy = JSON.parse(
          JSON.stringify(currentSetupFiles)
        );
        //@ts-ignore
        let images = [];
        values.forEach((value, index) => {
          imageUrls.push(value.Location);
          //@ts-ignore
          let key = value.key.split("/");
          let fileName = key[1].split("-")[1];
          const currentFileIndex = currentSetupFilesCopy.findIndex(
            //@ts-ignore
            (file) => file.fileName === fileName
          );
          console.log("filename: ", values);
          currentSetupFilesCopy[currentFileIndex]["src"] = value.Location;

          let imageObject = {
            src: value.Location,
            markers: currentSetupFilesCopy[currentFileIndex]["markers"],
          };
          images.push(imageObject);
          var result = currentSetupFilesCopy.filter(
            (obj: { fileName: string }) => {
              return obj.fileName === fileName;
            }
          );
        });
        let setup = {
          link: "/setup/" + setupName.toLowerCase().replaceAll(" ", "-"),
          name: setupName,
          products: products,
          instagramLink: instagramLink,
          //@ts-ignore
          images: images,
        };
        console.log("one setup: ", setup);
        setCurrentSetupFiles(currentSetupFilesCopy);
      })
      .catch((error) => {
        console.log("Error here: ", error);
      });

    //setImageUrl(Location);
    //console.log("uploading to s3", Location);
  };

  // const content = [
  //   { id: "introduction", text: "This is the introduction to the article." },
  //   { id: "section1", text: "This is the first section of the article." },
  //   { id: "section2", text: "This is the second section of the article." },
  //   { id: "conclusion", text: "This is the conclusion of the article." },
  // ];

  let background = location.state && location.state.background;

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <AppShell
          navbarOffsetBreakpoint="sm"
          asideOffsetBreakpoint="sm"
          // navbar={
          //   <Navbar
          //     p="md"
          //     hiddenBreakpoint="sm"
          //     hidden={!opened}
          //     width={{ sm: 200, lg: 300 }}
          //   >
          //     <Text>Application navbar</Text>
          //   </Navbar>
          // }
          // aside={
          //   <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          //     <Aside p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }}>
          //       <Text>Application sidebar</Text>
          //     </Aside>
          //   </MediaQuery>
          // }
          footer={
            <FooterLinks
              data={[
                {
                  title: "About",
                  links: [
                    { label: "Privacy Policy", link: "/privacy-policy" },
                    {
                      label: "Affiliate Disclosure",
                      link: "/disclosure",
                    },
                    {
                      label: "Contact Us",
                      link: "/contact",
                    },
                    {
                      label: "Cookie Policy",
                      link: "/cookie-policy",
                    },
                  ],
                },
              ]}
            />
          }
          header={
            <Header height={{ base: 50, md: 70 }} p="md">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Group style={{ display: "flex" }} position="left" noWrap>
                  <Drawer
                    opened={openedBurger}
                    onClose={close}
                    size={"xs"}
                    title=""
                    closeOnClickOutside
                  >
                    {/* Drawer content */}
                    <Stack justify="center">
                      <Button
                        onClick={() => clickedDrawerButton(0)}
                        variant="gradient"
                        component={Link}
                        to={"/"}
                        gradient={{ from: "indigo", to: "cyan" }}
                      >
                        Home
                      </Button>
                      {/* <Button
                          onClick={() => clickedDrawerButton(1)}
                          variant="gradient"
                          component={Link}
                          to={"/blog"}
                          gradient={{ from: "indigo", to: "cyan" }}
                        >
                          Blog
                        </Button> */}
                    </Stack>
                  </Drawer>
                  {/* <Burger
                    opened={openedBurger}
                    onClick={toggle}
                    className={classes.burger}
                    size="sm"
                  /> */}
                  <Image
                    maw={40}
                    height={"40px"}
                    width={"40px"}
                    radius="md"
                    src={DSB}
                    alt="Desk Setup Builder Logo"
                  />
                  {/* <Text>Desk Setup Builder</Text> */}

                  <Group className={classes.topTabs} noWrap>
                    {items}
                  </Group>
                </Group>

                {/* <Group style={{ display: "flex" }} position="center" noWrap>
                  <Text
                    variant="gradient"
                    gradient={{ from: "#03ecfc", to: "#ff30e3", deg: 45 }}
                    sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                    styles={(theme) => ({
                      track: {
                        background: theme.fn.linearGradient(45, "red", "blue"),
                      },
                    })}
                    ta="center"
                    fz="xl"
                    fw={700}
                  >
                    Desk Setup Builder
                  </Text>
                </Group> */}
                <Group spacing={0} position="right" noWrap>
                  <Modal
                    opened={openAddModal}
                    onClose={closeSetupModal}
                    title="Add a setup"
                  >
                    <form
                      onSubmit={setupForm.onSubmit((values) =>
                        addSetupName(values)
                      )}
                    >
                      <TextInput
                        withAsterisk
                        value={setupName}
                        label="Setup Name"
                        placeholder="Anime Vibes"
                        onChange={updateSetupName}
                      />
                      <TextInput
                        onChange={updateInstagramLink}
                        value={instagramLink}
                        withAsterisk
                        label="Instagram Link"
                        placeholder="Instagram..."
                      />
                    </form>
                    <Tabs defaultValue="gallery">
                      <Tabs.List>
                        <Tabs.Tab
                          value="gallery"
                          icon={<IconPhoto size="0.8rem" />}
                        >
                          Gallery
                        </Tabs.Tab>
                        <Tabs.Tab
                          value="products"
                          icon={<IconSettings size="0.8rem" />}
                        >
                          Products
                        </Tabs.Tab>
                      </Tabs.List>

                      <Tabs.Panel value="gallery" pt="xs">
                        <DropzoneButton
                          //@ts-ignore
                          updateCurrentSetupFiles={updateCurrentSetupFiles}
                          products={products}
                        />
                      </Tabs.Panel>

                      <Tabs.Panel value="messages" pt="xs">
                        Messages tab content
                      </Tabs.Panel>

                      <Tabs.Panel value="products" pt="xs">
                        <div
                          style={{ border: "1px solid lightgray", padding: 10 }}
                        >
                          <form
                            onSubmit={form.onSubmit((values) =>
                              addProduct(values)
                            )}
                          >
                            <TextInput
                              withAsterisk
                              label="Product Name"
                              placeholder="Monitor"
                              {...form.getInputProps("name")}
                            />
                            <TextInput
                              withAsterisk
                              label="Product Link"
                              placeholder="link"
                              {...form.getInputProps("link")}
                            />
                            <TextInput
                              withAsterisk
                              label="Product Retailer"
                              placeholder="Amazon"
                              {...form.getInputProps("retailer")}
                            />

                            <Group position="right" mt="md">
                              <Button type="submit">Add Product</Button>
                            </Group>
                          </form>
                        </div>
                        <div style={{ marginTop: 10 }}>
                          {products.map((item) => (
                            <Card
                              padding="md"
                              style={{
                                marginBottom: "10px",
                                border: "1px solid lightgray",
                                display: "flex",
                              }}
                            >
                              <div style={{ width: "100%" }}>
                                {/* @ts-ignore */}
                                <Text>{item.name}</Text>
                                <Text color="dimmed" size="sm">
                                  {/* @ts-ignore */}
                                  Retailer: {item.retailer} • Link:{" "}
                                  {/* @ts-ignore */}
                                  <a href={item.link}>{item.name}</a>
                                </Text>
                              </div>
                              <ActionIcon
                                onClick={() => deleteProduct(item)}
                                size="lg"
                                mr={15}
                              >
                                <IconTrash
                                  style={{ color: "red" }}
                                  size="1.5em"
                                  stroke={1.5}
                                />
                              </ActionIcon>
                            </Card>
                          ))}
                        </div>
                      </Tabs.Panel>
                    </Tabs>
                    <Center style={{ border: "0px solid lightgray" }}>
                      <Button
                        type="submit"
                        onClick={uploadToS3}
                        variant="gradient"
                        gradient={{ from: "teal", to: "lime", deg: 105 }}
                      >
                        Save
                      </Button>
                      <Space w="md" />
                      <Button
                        onClick={closeSetupModal}
                        variant="gradient"
                        gradient={{ from: "indigo", to: "cyan" }}
                      >
                        Close
                      </Button>
                    </Center>
                  </Modal>
                  {/* <Button
                    onClick={addHandlers.open}
                    style={{ marginRight: 15 }}
                  >
                    Add a setup
                  </Button> */}
                  <ActionIcon
                    aria-label="desk setup builder instagram page link"
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/desksetupbuilder/",
                        "_blank"
                      )
                    }
                    size="lg"
                    mr={15}
                  >
                    <IconBrandInstagram size="1.5em" stroke={1.5} />
                  </ActionIcon>
                  <ActionIcon
                    variant="outline"
                    color={dark ? "yellow" : "blue"}
                    onClick={() => toggleColorScheme()}
                    title="Toggle color scheme"
                  >
                    {dark ? (
                      <IconSun size="1.1em" />
                    ) : (
                      <IconMoonStars size="1.1em" />
                    )}
                  </ActionIcon>
                </Group>
              </div>
            </Header>
          }
        >
          <Center>
            <EmailBanner />
          </Center>
          <Space h="sm" />
          <Center>
            <Title className={classes.title}>
              Looking for inspiration for your{" "}
              <Text
                component="span"
                inherit
                variant="gradient"
                gradient={{ from: "lightgreen", to: "blue" }}
              >
                setup or workspace?
              </Text>{" "}
              You're in the right place.
            </Title>
          </Center>
          <Routes location={background || location}>
            <Route
              path="/"
              element={
                <Center>
                  {/* <TableOfContentsFloating
                      links={linksToc}
                      content={content}
                    /> */}
                  <Grid className={classes.setupsGrid}>
                    {setups.map((setup, index) => {
                      return (
                        <Grid.Col
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          span={"content"}
                        >
                          {/*@ts-ignore*/}
                          <SetupCard data={setup} />
                        </Grid.Col>
                      );
                    })}
                    {/* <Grid.Col md={6} lg={3}>
                        <SetupCard />
                      </Grid.Col>
                      <Grid.Col md={6} lg={3}>
                        <SetupCard />
                      </Grid.Col>
                      <Grid.Col md={6} lg={3}>
                        <SetupCard />
                      </Grid.Col>
                      <Grid.Col md={6} lg={3}>
                        <SetupCard />
                      </Grid.Col> */}
                  </Grid>
                </Center>
              }
            />
            <Route
              path="/blog"
              element={
                <Center>
                  <Grid style={{}}>
                    <Grid.Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      span={"auto"}
                    >
                      <ArticleCard
                        description="There are many components that make a great setup but here are some important ones to keep in mind."
                        link="/blog/what-makes-a-great-desk-setup"
                        title="What makes a great desk setup?"
                        rating="Guide"
                        image="https://kawaiilabs.s3.amazonaws.com/good-desk-setup/good-desk-setup-card.jpg"
                        author={{
                          name: "Lmaoo",
                          image: "",
                        }}
                      />
                    </Grid.Col>
                  </Grid>
                </Center>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/disclosure" element={<AffiliateDisclosure />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          {background && (
            <Routes>
              <Route
                path="/setup/:id"
                //@ts-ignore
                element={<SetupCardModal data={setups[0]} />}
              />
              <Route
                path="/blog/:id"
                //@ts-ignore
                element={<ArticleModal />}
              />
            </Routes>
          )}
          {/* {storedCookieConsent === null ? (
            <Affix position={{ bottom: rem(20), right: rem(20) }}>
              <CookiesBanner />
            </Affix>
          ) : null} */}
          {/* <Affix position={{ bottom: rem(20), right: rem(20) }}>
            <CookiesBanner />
          </Affix> */}
          <GoogleAnalytics />
          {/* <CookieConsentComponent /> */}
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Affix
              style={{ width: "100%", height: "fit-content" }}
              position={{ bottom: rem(0), right: rem(0) }}
            >
              <Center
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  display: "flex",
                  height: "fit-content",
                }}
              >
                {links.map((link) => {
                  return (
                    <Link
                      style={{ boxShadow: "0 0 0 0" }}
                      key={link.label}
                      to={link.link}
                      className={cx(classes.bottomNavLink, {
                        [classes.bottomNavLinkActive]: active === link.link,
                      })}
                      onClick={(event) => {
                        //event.preventDefault();
                        setActive(link.link);
                      }}
                    >
                      <ActionIcon
                        style={{
                          boxShadow: "0 0 0 0",
                          color: "inherit",
                          width: "100%",
                          borderRadius: 0,
                          height: "fit-content",
                        }}
                        variant="transparent"
                      >
                        <div style={{ textAlign: "center" }}>
                          {link.icon}
                          <Text size={"xs"} ta="center">
                            {link.label}
                          </Text>
                        </div>
                      </ActionIcon>
                    </Link>
                  );
                })}
              </Center>
            </Affix>
          </MediaQuery>
        </AppShell>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
