import React, { useEffect } from "react";
//@ts-ignore
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import ReactGA from "react-ga4";

export default function GoogleAnalytics() {
  const location = useLocation();

  return (
    <div>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-VCMH6TWWZH"
        ></script>
        <script data-cookiecategory="analytics" type="text/plain">
          {`
            window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('consent', 'default', {
                'ad_storage': 'denied',
                'analytics_storage': 'denied',
                'personalization_storage': 'denied',
                'functionality_storage': 'denied',
                'security_storage': 'denied',
            });

      gtag('config', 'G-VCMH6TWWZH');
            `}
        </script>

        {/* 
        <script type="text/plain" data-cookiecategory="analytics">
          {`
          window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}

             gtag('consent', 'update', {
             'analytics_storage': 'granted'
            });
        `}
        </script> */}
      </Helmet>
      {/* Rest of your component */}
    </div>
  );
}
