import {
  Text,
  Table,
  Button,
  Modal,
  rem,
  AspectRatio,
  Tooltip,
  ActionIcon,
  CopyButton,
  Affix,
  Skeleton,
  Image,
} from "@mantine/core";
import { useDisclosure, useHover, useMediaQuery } from "@mantine/hooks";
import { Carousel, useAnimationOffsetEffect } from "@mantine/carousel";
import { useCallback, useEffect, useRef, useState } from "react";
import { Embla } from "@mantine/carousel";
import { IconBrandInstagram, IconCheck, IconCopy } from "@tabler/icons-react";
import ImageMarker, { MarkerComponentProps } from "./imagemarkers";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
//@ts-ignore
import { gtag, install } from "ga-gtag";
import setups from "./setups.json";
import "./styles.css";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
//@ts-ignore
import LazyLoad from "react-lazyload";

interface SetupCardProps {
  data: {
    link: string;
    name: string;
    instagramLink: string;
    images: {
      src: string;
      markers: { top: number; left: number; tableRowId: string }[];
    }[];
    products: { name: string; price: string; link: string; retailer: string }[];
  };
}

export default function SetupCardModal({ data }: SetupCardProps) {
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [opened, { close }] = useDisclosure(true);
  const [embla, setEmbla] = useState<Embla | null>(null);
  const TRANSITION_DURATION = 200;
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);
  const navigate = useNavigate();
  const mounted = useRef(false);
  let { id } = useParams();
  //@ts-ignore
  const setup = setups.find((x) => x.link.includes(id));

  useEffect(() => {
    if (highlightedIndex) {
      const timeoutId = setTimeout(() => {
        setHighlightedIndex(null);
      }, 1500);

      return () => clearTimeout(timeoutId);
    }
  }, [highlightedIndex]);

  const targetRefs = useRef<(HTMLTableRowElement | null)[]>([]);

  const handleClick = (index: number) => {
    if (targetRefs.current[index]) {
      targetRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
      setHighlightedIndex(index);
    }
  };

  const visitMerchant = (link: string, retailer: string, name: string) => {
    // ReactGA.event({
    //   category: "Buy",
    //   action: "clicked on buy button for " + retailer,
    //   label: "buy button", // optional
    //   value: 99, // optional, must be a number
    //   nonInteraction: false, // optional, true/false
    // });

    //@ts-ignore
    if (window.gtag) {
      //@ts-ignore
      window.gtag("event", "Visited Retailer: " + retailer, {
        event_category: "Buy Button",
        event_label: "Clicked Product: " + name + " Retailer: " + retailer,
        value: "Clicked Product: " + name + " Retailer: " + retailer,
        action: "Clicked Product: " + name + " Retailer: " + retailer,
      });
    }

    window.open(link, "_blank");
  };

  const [toolTipsOpened, setTipsOpened] = useState(false);
  const CustomMarker = (props: MarkerComponentProps) => {
    const { hovered, ref } = useHover();
    let itemNumber = String(props.itemNumber);
    let num = +itemNumber;
    //@ts-ignore
    if (num >= setup.products.length) num -= 1;
    //@ts-ignore
    let tableRowIdString = String(props.tableRowId);
    let targetNum = +tableRowIdString;
    console.log("marker props: ", tableRowIdString);
    //@ts-ignore
    console.log("available products: ", setup.products[targetNum]);
    return (
      <>
        <Tooltip
          position="bottom"
          //@ts-ignore
          label={setup.products[targetNum].name}
          style={{ border: "2px solid white" }}
          color="black"
          withArrow={false}
        >
          <div
            ref={ref}
            onClick={() => handleClick(targetNum)}
            style={{
              backgroundColor: "white",
              opacity: hovered ? 1 : 0.7,
              border: "1px solid black",
              width: "1em",
              height: "1em",
              borderRadius: 50,
              color: "black",
            }}
          ></div>
        </Tooltip>
      </>
    );
  };

  const openAllTooltips = () => {
    setTipsOpened(!toolTipsOpened);
  };

  const closeModal = () => {
    close();
    navigate("/");
  };

  // const openModal = (link: string) => {
  //   open();
  // };

  const matches = useMediaQuery("(max-width: 56.25em)");

  const imgRef = useRef();
  //@ts-ignore
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      //@ts-ignore
      img.style.setProperty("transform", value);
    }
  }, []);

  return (
    <>
      <Modal
        transitionProps={{ duration: TRANSITION_DURATION }}
        fullScreen={matches}
        opened={opened}
        onClose={closeModal}
        centered={!matches}
        title={
          <div
            style={{
              display: "flex",
              alignContent: "center",
            }}
          >
            {/* @ts-ignore */}
            <Text>{setup.name}</Text>
            <CopyButton value={window.location.href} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copied" : "Copy"}
                  withArrow
                  position="right"
                >
                  <ActionIcon
                    style={{ marginLeft: 5 }}
                    color={copied ? "teal" : "gray"}
                    onClick={copy}
                  >
                    {copied ? (
                      <IconCheck size="1em" />
                    ) : (
                      <IconCopy size="1em" />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
            <ActionIcon
              style={{ marginLeft: 5 }}
              //@ts-ignore
              onClick={() => window.open(setup.instagramLink, "_blank")}
              size="md"
            >
              <IconBrandInstagram size="1.5em" stroke={1.5} />
            </ActionIcon>
          </div>
        }
      >
        <Carousel
          getEmblaApi={setEmbla}
          withIndicators={setup && setup.images.length > 1 ? true : false}
          mx="auto"
          align={"center"}
          slideGap={0}
          sx={(theme) => ({
            width: "100%",
            border: "0px solid red",
            backgroundImage: theme.fn.gradient({
              from: "green",
              to: "#57d8ff",
              deg: 0,
            }),
          })}
          styles={{
            control: {
              "&[data-inactive]": {
                opacity: 0,
                cursor: "default",
              },
            },
            indicator: {
              width: rem(12),
              height: rem(4),
              transition: "width 250ms ease",

              "&[data-active]": {
                width: rem(40),
              },
            },
          }}
        >
          {/* @ts-ignore */}
          {setup.images.map((image, index) => {
            console.log("one setup: ", setup);
            return (
              <Carousel.Slide key={index} style={{}}>
                {/* @ts-ignore */}
                <AspectRatio ratio={setup["ratio"]} mx={"auto"} maw={700}>
                  {/* <Image
                    withPlaceholder
                    src={image.src}
                    height={"100%"}
                    width={"100%"}
                    alt="Norway"
                  /> */}
                  <div onClick={openAllTooltips}>
                    <ImageMarker
                      extraClass="imageMarker"
                      //@ts-ignore
                      markerComponent={CustomMarker}
                      //@ts-ignore
                      src={image.src}
                      markers={image.markers}
                    />
                  </div>
                </AspectRatio>
              </Carousel.Slide>
            );
          })}
        </Carousel>
        <Text
          c="dimmed"
          sx={{
            display: setup && setup.products.length > 0 ? "block" : "none",
            textAlign: "center",
            fontSize: 12,
            marginTop: 0,
          }}
        >
          This list may contain links and Desk Setup Builder may receive
          compensation for products purchased through these links. Linked
          products may not be exactly what is shown in above images.
        </Text>
        <div
          style={{
            display: setup && setup.products.length > 0 ? "block" : "none",
            overflow: "auto",
            height: "30vh",
            borderBottom: "1px solid lightgray",
          }}
        >
          <Table
            sx={{
              tableLayout: "fixed",
            }}
            withBorder
          >
            <thead style={{}}>
              <tr>
                <th style={{ width: "50%" }}>Product</th>
                <th style={{ width: "50%", textAlign: "right" }}>
                  Merchant Link
                </th>
              </tr>
            </thead>
            <tbody style={{ maxHeight: 100, overflow: "scroll" }}>
              {/* @ts-ignore */}
              {setup.products.map((element, index) => (
                <tr
                  ref={(ref) => (targetRefs.current[index] = ref)}
                  key={element.name}
                  style={{
                    transition: "background-color 0.5s",
                    backgroundColor:
                      highlightedIndex === index ? "#3b83ff" : undefined,
                  }}
                >
                  <td style={{ wordBreak: "break-word" }}>{element.name}</td>
                  <td style={{ maxWidth: "50%" }}>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "right",
                        justifyContent: "flex-end",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Text>${element.price}</Text> */}
                      {element.link.length > 0 ? (
                        <Button
                          sx={{
                            fontSize: 12,
                            padding: 5,
                            paddingTop: 0,
                            paddingBottom: 3,
                            marginLeft: 10,
                            minWidth: 100,
                          }}
                          onClick={() =>
                            visitMerchant(
                              element.link,
                              element.retailer,
                              element.name
                            )
                          }
                          variant="gradient"
                          gradient={{ from: "teal", to: "lime", deg: 105 }}
                        >
                          Buy on {element.retailer}
                        </Button>
                      ) : (
                        <Text>Visit Merchant Website</Text>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        {/* <List
          style={{
            border: "1px solid red",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            Clone or download repository from GitHub
          </List.Item>
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            Install dependencies with yarn
          </List.Item>
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            To start development server run npm start command
          </List.Item>
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            Run tests to make sure your changes do not break the build
          </List.Item>
          <List.Item sx={{ maxWidth: "100%", border: "0px solid red" }}>
            Submit a pull request once you are done
          </List.Item>
        </List> */}
      </Modal>
    </>
  );
}
