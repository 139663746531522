import { Center } from "@mantine/core";

export default function Contact() {
  return (
    <Center maw={1000} h={"100%"} style={{ overflow: "scroll" }} mx="auto">
      <div>
        <h1>Contact</h1>
        <p>
          Would you like to have your setup featured or just have
          questions/feedback? Please contact us at desksetupbuilder@gmail.com
        </p>
      </div>
    </Center>
  );
}
