import { Center } from "@mantine/core";

export default function AffiliateDisclosure() {
  return (
    <Center maw={1000} h={"100%"} style={{ overflow: "scroll" }} mx="auto">
      <div>
        <h1>Disclaimer for desksetupbuilder.com</h1>

        <p>
          If you require any more information or have any questions about our
          site's disclaimer, please feel free to contact us by email at
          desksetupbuilder@gmail.com. Our Disclaimer was generated with the help
          of the{" "}
          <a href="https://www.disclaimergenerator.net/">
            Free Disclaimer Generator
          </a>
          .
        </p>

        <h2>Affiliate Disclosure</h2>

        <p>
          Desk Setup Builder is a participant in the Amazon Services LLC
          Associates Program. This is a program that allows Desk Setup Builder
          To receive commissions for purchases made through links on this
          website. This does not impact the content shared on this website.
        </p>

        <h2>Disclaimers for Desk Setup Builder</h2>

        <p>
          All the information on this website - desksetupbuilder.com - is
          published in good faith and for general information purpose only. Desk
          Setup Builder does not make any warranties about the completeness,
          reliability and accuracy of this information. Any action you take upon
          the information you find on this website (Desk Setup Builder), is
          strictly at your own risk. Desk Setup Builder will not be liable for
          any losses and/or damages in connection with the use of our website.
        </p>

        <p>
          From our website, you can visit other websites by following hyperlinks
          to such external sites. While we strive to provide only quality links
          to useful and ethical websites, we have no control over the content
          and nature of these sites. These links to other websites do not imply
          a recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before we have the
          opportunity to remove a link which may have gone 'bad'.
        </p>

        <p>
          Please be also aware that when you leave our website, other sites may
          have different privacy policies and terms which are beyond our
          control. Please be sure to check the Privacy Policies of these sites
          as well as their "Terms of Service" before engaging in any business or
          uploading any information.
        </p>

        <h2>Consent</h2>

        <p>
          By using our website, you hereby consent to our disclaimer and agree
          to its terms.
        </p>

        <h2>Update</h2>

        <p>
          Should we update, amend or make any changes to this document, those
          changes will be prominently posted here.
        </p>
      </div>
    </Center>
  );
}
